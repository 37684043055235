import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"

const StyledNotFoundPage = styled.div`
  text-align: center;
  p {
    max-width: 400px;
    margin: auto;
  }
`

const NotFoundPage = () => {
  return (
    <Layout>
      <StyledNotFoundPage>
        <h2>Sidan hittades inte.</h2>
        <p>
          Sidan du söker kunde inte hittas.
          <br />
          Prova gå tillbaka till <Link to="/">inloggningssidan</Link>
        </p>
      </StyledNotFoundPage>
    </Layout>
  )
}

export default NotFoundPage
